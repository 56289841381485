import React from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { MenuContent } from "../components/shared";
import routes from "../lib/routes";
import BasicLayout, { BasicLayoutProps } from "./BasicLayout";
import { useCurrentUser } from "../lib/hooks";

type SettingsMenuItems = Record<
  string,
  {
    title: React.ReactNode;
    group: string;
    route?: string;
    header?: React.ReactNode;
  }
>;

const settingsMenuItems = () => {
  const items: SettingsMenuItems = {
    companyAccount: {
      title: (
        <FormattedMessage
          id="company.settings.account"
          defaultMessage="Company Account"
        />
      ),
      group: "companyAccount",
    },
    basicInfo: {
      title: <FormattedMessage id="basicInfo" />,
      group: "companyAccount",
      route: routes.tenants.settings.basic.index,
    },
    inventory: {
      title: <FormattedMessage id="inventory" />,
      header: (
        <FormattedMessage
          id="company.settings.inventory.header"
          defaultMessage="Inventory Management Settings"
        />
      ),
      group: "companyAccount",
      route: routes.tenants.settings.inventory,
    },
    accounting: {
      title: (
        <FormattedMessage
          id="company.settings.accounting"
          defaultMessage="Finance & Accounting"
        />
      ),
      group: "companyAccount",
      route: routes.tenants.settings.accounting,
    },
    hr: {
      title: <FormattedMessage id="hr" />,
      group: "companyAccount",
      route: routes.tenants.settings.hr,
    },
  };

  return items;
};

const messages = defineMessages({
  pageTitle: {
    id: "companies.pageTitle",
    defaultMessage: "Company Settings",
  },
});

export default function CompanySettingsLayout({
  children,
  selectedKey,
  ...props
}: BasicLayoutProps & {
  children?: React.ReactNode;
  selectedKey: keyof SettingsMenuItems;
}) {
  const intl = useIntl();
  const title = intl.formatMessage(messages.pageTitle);
  const { user } = useCurrentUser();

  if (!user) return null;

  const items = settingsMenuItems();

  return (
    <BasicLayout
      authorize={(p) => !!p?.settings?.company && !!items[selectedKey]}
      breadcrumbs={[
        {
          path: routes.tenants.settings.basic.index,
          breadcrumbName: title,
        },
      ]}
      {...props}
      title={title}
    >
      <MenuContent selectedKey={selectedKey} items={items}>
        {children}
      </MenuContent>
    </BasicLayout>
  );
}
