import { Col, Divider, Row } from "antd";
import { FormattedMessage } from "react-intl";
import { formatMoneyAmount, formatNumber } from "../../lib/formats";
import { useCurrentUser, usePermissions } from "../../lib/hooks";
import routes from "../../lib/routes";
import { LocalityOverview, WorkOrders } from ".";
import { useDashboardReport } from "../../lib/hooks/reports/dashboardReport";
import DashboardCard from "../../components/dashboard/DasboardCard";
import { Weather } from "./Weather";
import ActivitiesPie from "./ActivitiesPie";
import { UpcomingInvoicesTable } from "./UpcomingInvoicesTable";
import ExpenseCategoriesPie from "./ExpenseCategoriesPie";
import { RecentPurchases } from "./RecentPurchases";
import DashboardCol from "./DashboardCol";
import { Space } from "../shared";
import { CurrencyFragment } from "../../lib/graphql";
import { HarvestOverview } from "./HarvestOverview";

export default function Dashboard({
  currentCurrency,
}: {
  currentCurrency: CurrencyFragment;
}) {
  const { currentTenant, currentLocality, currentCropCycle, user } =
    useCurrentUser();
  const showCost = usePermissions((p) => !!p.settings?.showCost);

  const currencyCode = currentCurrency.isoCode;
  const localityId = currentLocality?.id;
  const cropCycleId = currentCropCycle?.id;

  const { loading, expenses, workOrders, localitySummary } = useDashboardReport(
    {
      // skip if tenant is not loaded or current locality is not set yet
      skip: !!currentTenant.localities.length && !currentLocality?.id,
      variables: {
        localityId,
        cropCycleId,
        currencyCode,
        showCost,
      },
    }
  );

  const showExpenses = user?.permissions.report?.expense;

  return (
    <>
      <Space vertical>
        <Row gutter={[8, 8]}>
          {showCost && (
            <Col flex={1}>
              <DashboardCard
                loading={loading}
                value={formatMoneyAmount(
                  expenses?.totalExpenses || 0,
                  currencyCode
                )}
                title={
                  <FormattedMessage
                    id="totalExpenses"
                    defaultMessage="totalExpenses"
                  />
                }
                icon="expenses"
              />
            </Col>
          )}

          <Col flex={1}>
            <DashboardCard
              loading={loading}
              value={formatNumber(expenses?.totalManDays)}
              title={
                <FormattedMessage
                  id="totalManDays"
                  defaultMessage="totalManDays"
                />
              }
              icon="man-days"
            />
          </Col>

          <Col flex={1}>
            <DashboardCard
              loading={loading}
              value={formatNumber(expenses?.totalMachineHours)}
              title={
                <FormattedMessage
                  id="totalMachineHours"
                  defaultMessage="totalMachineHours"
                />
              }
              icon="machine-hours"
            />
          </Col>

          <Col flex={1}>
            <Weather />
          </Col>
        </Row>

        <Row gutter={[8, 8]}>
          {user?.permissions.report?.harvest && <HarvestOverview />}

          <DashboardCol
            loading={loading}
            link={routes.reports.activities}
            title={
              <FormattedMessage id="activities" defaultMessage="Activities" />
            }
            hasData={!!expenses?.activityExpenses?.length}
            noDataLink={routes.agro.workOrders.index}
            noDataLinkText={
              <FormattedMessage
                id="dashboard.logActivity"
                defaultMessage="Log Activity"
              />
            }
            noDataHint={
              <FormattedMessage
                id="dashboard.logActivity.hint"
                defaultMessage="It looks like you didn't create any Work Orders yet."
              />
            }
          >
            <div>
              <ActivitiesPie
                activityExpenses={expenses?.activityExpenses}
                currency={currencyCode}
              />
            </div>
          </DashboardCol>

          {showExpenses && (
            <DashboardCol
              loading={loading}
              link={routes.reports.expense}
              title={
                <FormattedMessage id="expenses" defaultMessage="Expenses" />
              }
              hasData={!!expenses?.allExpenses?.length}
              noDataHint={
                <FormattedMessage
                  id="dashboard.logExpense.hint"
                  defaultMessage="It looks like you didn't record any expenses yet."
                />
              }
              noDataLink={routes.finance.expenseOrders.new}
              noDataLinkText={
                <FormattedMessage
                  id="dashboard.logExpense"
                  defaultMessage="Log Expense"
                />
              }
            >
              <div>
                <ExpenseCategoriesPie
                  expenses={expenses?.allExpenses}
                  currencyCode={currencyCode}
                />
              </div>
            </DashboardCol>
          )}

          <RecentPurchases
            currencyCode={currencyCode}
            currentLocality={currentLocality}
          />

          <UpcomingInvoicesTable
            currencyCode={currencyCode}
            colProps={{ xs: 24, md: showExpenses ? 12 : 24 }}
          />
        </Row>
      </Space>

      {!!workOrders?.workOrderTypeSummary.length && (
        <>
          <Divider>
            <FormattedMessage id="workOrders" />
          </Divider>

          <WorkOrders loading={loading} {...workOrders} />
        </>
      )}

      <Divider>
        <FormattedMessage
          id="dashboard.farmOverview"
          defaultMessage="farmOverview"
        />
      </Divider>

      <LocalityOverview
        loading={loading}
        currentLocality={currentLocality}
        {...localitySummary}
      />
    </>
  );
}
