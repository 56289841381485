import { Col, Divider, Form, Input, Row, Typography } from "antd";
import Router from "next/router";
import React from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useEffectOnce } from "react-use";
import { Rules, SelectField, Upload } from "../../../components/form";
import {
  AddressInput,
  EditForm,
  HelpTooltip,
  Space,
} from "../../../components/shared";
import CompanySettingsLayout from "../../../layouts/CompanySettingsLayout";
import { formatAssetPath, formatTaxIDLabel } from "../../../lib/formats";
import { TenantFragment } from "../../../lib/graphql";
import {
  useCountryOptions,
  useCurrencyOptions,
  useCurrentUser,
  useLocaleOptions,
  useTenant,
  useTenantUpdate,
} from "../../../lib/hooks";
import routes from "../../../lib/routes";

const messages = defineMessages({
  fullNamePlaceholder: {
    id: "tenants.fullName.placeholder",
    defaultMessage: "Enter full name",
  },
  legalNamePlaceholder: {
    id: "tenants.legalName.placeholder",
    defaultMessage: "Enter business name",
  },
  taxIdPlaceholder: {
    id: "tenants.taxId.placeholder",
    defaultMessage: "Enter tax ID",
  },
});

export default function CompanySettingsEdit() {
  const { currentTenant } = useCurrentUser();

  const tenant = useTenant({ id: currentTenant.id, withLocalities: false });

  if (!currentTenant || !tenant)
    return <CompanySettingsLayout loading selectedKey="basicInfo" />;

  return (
    <CompanySettingsLayout selectedKey="basicInfo">
      <EditForm
        item={tenant}
        showHeader={false}
        entityName=""
        mutation={useTenantUpdate}
        onMutate={(result) => result.tenantUpdate}
        onSuccess={() => Router.push(routes.tenants.settings.basic.index)}
        onCancel={() => Router.push(routes.tenants.settings.basic.index)}
        formValues={(item) => ({
          fullName: item.fullName,
          legalName: item.legalName,
          taxId: item.taxId,
          addressAttributes: item.address && {
            countryCode: item.address.country.code,
            region: item.address.region,
            city: item.address.city,
            address: item.address.address,
            phoneCode: item.address.phoneCode,
            phoneNumber: item.address.phoneNumber,
          },
          localeCode: item.localeCode,
          website: item.website,
          currencyCode: item.currency.isoCode,
        })}
        fields={[
          {
            type: "custom",
            render: () => (
              <div style={{ maxWidth: "700px" }}>
                <CompanySettingsForm key="basic" tenant={tenant} />
              </div>
            ),
          },
        ]}
      />
    </CompanySettingsLayout>
  );
}

export function CompanySettingsForm({ tenant }: { tenant: TenantFragment }) {
  const intl = useIntl();
  const form = Form.useFormInstance();

  const { load, countries } = useCountryOptions();

  useEffectOnce(() => {
    load();
  });

  const onCountryChange = (countryCode: string) => {
    form.setFieldsValue({
      currencyCode: countries.find((c) => c.code === countryCode)?.currency
        ?.isoCode,
    });
  };

  return (
    <>
      <Row gutter={16}>
        <Col xs={16} xl={18}>
          {[
            {
              label: (
                <FormattedMessage
                  id="tenants.fullName"
                  defaultMessage="Display Name"
                />
              ),
              placeholder: intl.formatMessage(messages.fullNamePlaceholder),
              name: "fullName",
              rules: [Rules.required],
            },
            {
              label: (
                <FormattedMessage
                  id="tenants.legalName"
                  defaultMessage="Legal Name"
                />
              ),
              placeholder: intl.formatMessage(messages.legalNamePlaceholder),
              name: "legalName",
              rules: [Rules.required],
            },
          ].map((f) => (
            <Form.Item
              key={f.name}
              name={f.name}
              label={f.label}
              rules={f.rules}
            >
              <Input name={f.name} placeholder={f.placeholder} />
            </Form.Item>
          ))}
        </Col>

        <Col xs={8} xl={6}>
          <Space vertical align="center" justify="center">
            <Typography.Text>
              <FormattedMessage id="tenants.logo" />
            </Typography.Text>

            <Form.Item name="logo">
              <Upload
                name="logo"
                defaultPreview={formatAssetPath(tenant.logo)}
                style={{ margin: 0 }}
              />
            </Form.Item>

            <Typography.Text type="secondary">
              <FormattedMessage
                id="images.shouldBeSquare"
                defaultMessage="Use square image"
              />
            </Typography.Text>
          </Space>
        </Col>
      </Row>

      <Form.Item name="taxId" label={formatTaxIDLabel(tenant)}>
        <Input placeholder={intl.formatMessage(messages.taxIdPlaceholder)} />
      </Form.Item>

      <Divider>
        <FormattedMessage id="contactInfo" defaultMessage="Contact Info" />
      </Divider>

      <AddressInput
        name="addressAttributes"
        onCountryChange={onCountryChange}
      />

      <Form.Item
        label={
          <FormattedMessage
            id="tenants.website"
            defaultMessage="Company website"
          />
        }
        name="website"
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="localeCode"
        label={<FormattedMessage id="defaultLanguage" />}
        rules={[Rules.required]}
      >
        <SelectField optionsHook={useLocaleOptions} />
      </Form.Item>

      <HelpTooltip
        title={
          <FormattedMessage
            id="tenants.currencyHelp"
            defaultMessage="currencyHelp"
          />
        }
        trigger="hover"
        placement="top"
      >
        <Form.Item
          name="currencyCode"
          label={
            <FormattedMessage
              id="officialCurrency"
              defaultMessage="Official Currency"
            />
          }
          rules={[Rules.required]}
          dependencies={["addressAttributes.countryCode"]}
        >
          <SelectField
            disabled={!tenant.mayUpdateCurrency}
            optionsHook={useCurrencyOptions}
          />
        </Form.Item>
      </HelpTooltip>
    </>
  );
}
