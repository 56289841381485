import { Divider, Typography } from "antd";
import Link from "next/link";
import { FormattedMessage } from "react-intl";
import { useCurrentUser, useTenant, useTenantUpdate } from "../../lib/hooks";
import routes from "../../lib/routes";
import { CompanySettingsForm } from "../../pages/settings/company/edit";
import { Form } from "../form";
import { WizardButtons } from "./WizardButtons";

export function Company({ onNextClick }: { onNextClick: () => void }) {
  const { currentTenant } = useCurrentUser();

  const tenant = useTenant({ id: currentTenant.id, withLocalities: false });

  const [update] = useTenantUpdate();

  if (!tenant) return null;

  return (
    <>
      <Typography.Title level={3}>
        <FormattedMessage
          id="companies.information"
          defaultMessage="Company Information"
        />
      </Typography.Title>

      <Typography.Text type="secondary">
        <FormattedMessage
          id="companies.onboardingingHint"
          defaultMessage="informationHint"
          values={{
            link: (
              <Link href={routes.tenants.settings.basic.edit} target="_blank">
                <FormattedMessage
                  id="companies.settings"
                  defaultMessage="settings"
                />
              </Link>
            ),
          }}
        />
      </Typography.Text>

      <Divider />

      <Form
        initialValues={{
          fullName: tenant.fullName,
          legalName: tenant.legalName,
          taxId: tenant.taxId,
          addressAttributes: tenant.address && {
            countryCode: tenant.address.country.code || "NI",
            region: tenant.address.region,
            city: tenant.address.city,
            address: tenant.address.address,
            phoneCode:
              tenant.address.phoneCode || tenant.address.country.phoneCode,
            phoneNumber: tenant.address.phoneNumber,
          },
          localeCode: tenant.localeCode,
          currencyCode: tenant.address ? tenant.currency.isoCode : "NIO",
        }}
        layout="vertical"
        onSubmit={(changes, { setSubmitting }) =>
          update({ variables: { id: tenant.id, changes } }).then((result) => {
            if (result.data?.tenantUpdate?.result) {
              onNextClick();
            }

            setSubmitting(false);
          })
        }
      >
        <CompanySettingsForm tenant={tenant} />

        <Divider />

        <WizardButtons nextStep />
      </Form>
    </>
  );
}
