import { Card, Col, Row, Steps } from "antd";
import { useState } from "react";
import routes from "../../lib/routes";
import { Space } from "../shared";
import { Company } from "./Company";
import { Localities } from "./Localities";
import Welcome from "./Welcome";

export default function Onboarding() {
  const [currentStep, setCurrentStep] = useState("welcome");

  const onNextStep = (step: string) => () => {
    setCurrentStep(step);
    window.scrollTo(0, 0);
  };

  const finish = () => {
    location.href = routes.onboarding;
  };

  if (currentStep == "welcome") {
    return <Welcome onNextClick={onNextStep("companySettings")} />;
  } else {
    return (
      <Row justify="center" align="middle">
        <Col xs={24} md={16}>
          <Card>
            <Space vertical size="large">
              <Steps
                current={currentStep == "companySettings" ? 1 : 2}
                items={[
                  {
                    status:
                      currentStep == "companySettings" ? "process" : "finish",
                  },
                  {
                    status: currentStep == "localities" ? "process" : "wait",
                  },
                ]}
              />

              {currentStep == "companySettings" ? (
                <Company onNextClick={onNextStep("localities")} />
              ) : (
                <Localities onNextClick={finish} />
              )}
            </Space>
          </Card>
        </Col>
      </Row>
    );
  }
}
